import styled from '@emotion/styled';
import { breakpoints } from '../../../../utils/styles';

export const Container = styled.div`
  margin: 16px 0;
`;

export const Title = styled.p`
  font-size: 18px;
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.xl}px){
    font-size: 24px;
  }
`;