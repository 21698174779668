import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints, dimensions } from '../../../utils/styles';

export const Image = styled(Img)`
  margin: 0;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 96px 24px;

  @media (max-width: ${breakpoints.l}px){
    padding: 64px 24px;
  }

  @media (max-width: ${breakpoints.s}px){
    padding: 32px 24px;
  }
`;

export const Container = styled.div`
  max-width: 610px;
  color: ${({ theme }) => theme.textDark};

  @media (min-width: ${breakpoints.xl}px){
    max-width: 850px;
  }
`;

export const Description = styled.p`
  margin-bottom: 0;

  @media (min-width: ${breakpoints.xl}px){
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

export const InfoSection = styled.div`
  background: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.textLight};
  padding: 96px 24px;

  @media (max-width: ${breakpoints.l}px){
    padding: 64px 24px;
  }

  @media (max-width: ${breakpoints.s}px){
    padding: 32px 24px;
  }
`;

export const InfoContainer = styled.div`
  max-width: ${dimensions.contentWidth};
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-auto-flow: column;
  gap: 16px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
    gap: 0;
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoValue = styled.h4`
  font-size: 24px;
  margin: 8px 0;

  @media (min-width: ${breakpoints.xl}px){
    font-size: 32px;
  }
`;

export const InfoLink = styled.a`
  font-family: MuseoSans, Arial, sans-serif;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.2;
  cursor: pointer;
  font-size: 24px;
  margin: 8px 0;
  padding: 0;

  @media (min-width: ${breakpoints.xl}px){
    font-size: 32px;
  }
`;