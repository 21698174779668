import React from 'react';
import { Container, Title } from './styles';

const WorkInfo = ({ title, children }) => {
  return (
    <Container>
      <Title>
        {title}
      </Title>
      {children}
    </Container>
  );
};

export default WorkInfo;
