import React, { Fragment } from 'react';
import { RichText } from 'prismic-reactjs';

import { 
  Section, 
  Image, 
  Container, 
  Description, 
  InfoSection,
  InfoContainer,
  InfoColumn,
  InfoValue,
  InfoLink
} from './styles';

import WorkInfo from './WorkInfo';

const WorkHeader = ({ title, text, image, builtWith, builtFor, client, visit, year }) => {

  // Render image
  const renderSplashImage = (image) => {
    if (image) {
      return (
        <Image 
          fluid={image.fluid} 
          alt={image.alt}
        />
      );
    }  
  };

  return (
    <Fragment>
      {renderSplashImage(image)}
      <Section>
        <Container>
          <h1>
            {RichText.asText(title.raw)}
          </h1>
          <Description>
            {RichText.asText(text.raw)}
          </Description>
        </Container>
      </Section>
      <InfoSection>
        <InfoContainer>
          <InfoColumn>
            <WorkInfo title='Client'>
              <InfoValue>
                {client}
              </InfoValue>
            </WorkInfo>
            <WorkInfo title='Year'>
              <InfoValue>
                {year}
              </InfoValue>
            </WorkInfo>
          </InfoColumn>
          <WorkInfo title='Technologies'>
            {builtWith.map((item, i) => (
              <InfoValue key={`built-with-${i}`}>
                {item.technology}
              </InfoValue>
            ))}
          </WorkInfo>
          <InfoColumn>
            <WorkInfo title='Platforms'>
              {builtFor.map((item, i) => (
                <InfoValue key={`built-with-${i}`}>
                  {item.platform}
                </InfoValue>
              ))}
            </WorkInfo>
            <WorkInfo title='Visit'>
              {visit.map((site, i) => (
                <InfoLink 
                  key={`visit-${i}`} 
                  href={site.link.url}
                >
                  {site.link_name}
                </InfoLink>
              ))}
            </WorkInfo>
          </InfoColumn>
        </InfoContainer>
      </InfoSection>
    </Fragment>
  );
};

export default WorkHeader;
