import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../utils/styles';

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  color: ${({ theme }) => theme.textDark};
  margin: 0 auto;
  padding: 64px 24px;

  @media (min-width: ${breakpoints.xl}px){
    max-width: 850px;
  }
`;