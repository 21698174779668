import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Container } from './styles';

const WorkText = ({ slice }) => (
  <Container>
    {RichText.render(slice.primary.text_title.raw)}
    {RichText.render(slice.primary.text_body.raw)}
  </Container>
);

export default WorkText;