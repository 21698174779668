import React from 'react';
import { Container, Image } from './styles';

const WorkImage = ({ slice }) => {

  // Image
  const renderImage = (image, fullWidth) => {
    if (image) {
      return (
        <Image 
          fluid={image.fluid} 
          alt={image.alt}
          fullWidth={fullWidth}
        />
      );
    }
  };

  return (
    <Container fullWidth={slice.primary.full_width}>
      {renderImage(slice.primary.image, slice.primary.full_width)}
    </Container>
  );
};

export default WorkImage;