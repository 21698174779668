import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { dimensions, breakpoints } from '../../../utils/styles';

export const Container = styled.div`
  max-width: ${({ fullWidth }) => fullWidth ? 'none' : dimensions.contentWidth};
  margin: 0 auto;
  padding: ${({ fullWidth }) => fullWidth ? '0' : '0 24px'};

  @media (min-width: ${breakpoints.xl}px){
    max-width: ${({ fullWidth }) => fullWidth ? 'none' : '850px'};
  }
`;

export const Image = styled(Img)`
  margin: ${({ fullWidth }) => fullWidth ? '0 auto' : '32px auto'};
`;