import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import WorkHeader from '../components/work/WorkHeader';
import WorkImage from '../components/work/WorkImage';
import WorkText from '../components/work/WorkText';
import WorkGrid from '../components/work/WorkGrid';

const Work = ({ data }) => {
  // Props
  const allWork = data.allPrismicWork.nodes;
  const project = data.prismicProjects.data;
  const work = data.prismicWork.data;
  if (!work) return null;

  // Body
  const body = work.body.map((slice, index) => {
    switch (slice.slice_type) {
      case ('image'):
        return <WorkImage key={'slice-' + index} slice={slice} />;
      case ('text'):
        return <WorkText key={'slice-' + index} slice={slice} />;
      default:
        return null;
    }
  })

  return (
    <Layout>
      <SEO 
        title={work.meta_title}
        description={work.meta_description}
        image={work.featured_image}
        type='article'
      />
      <WorkHeader
        title={work.title}
        text={work.description}
        image={work.featured_image}
        builtWith={work.built_with}
        builtFor={work.built_for}
        client={work.client}
        visit={work.visit}
        year={work.year}
      />
      {body}
      <WorkGrid
        bgColor
        title={project.more_work_title}
        work={allWork}
      />
    </Layout>
  );
};

export default Work;

export const query = graphql`
  query Work($id: String!, $prev: String, $next: String) {
    prismicWork(id: {eq: $id}) {
      data {
        meta_description
        meta_title
        title {
          raw
        }
        visit {
          link_name
          link {
            url
          }
        }
        featured_image {
          alt
          url
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
        description {
          raw
        }
        built_with {
          technology
        }
        client
        body {
          ... on PrismicWorkBodyText {
            id
            slice_type
            primary {
              text_body {
                raw
              }
              text_title {
                raw
              }
            }
          }
          ... on PrismicWorkBodyImage {
            id
            primary {
              full_width
              image {
                alt
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
        }
        built_for {
          platform
        }
        year
      }
    }
    prismicProjects {
      data {
        more_work_title {
          raw
        }
      }
    }
    allPrismicWork(filter: {id: {in: [$prev, $next]}}) {
      nodes {
        uid
        type
        data {
          featured_image {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          title {
            raw
          }
        }
      }
    }
  }
`;